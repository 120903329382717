import Logo from '../shots_logo.svg';
import Clock from '../components/Clock';
import { useEffect, useState } from 'react';
import { listPeople } from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify'
import { updatePerson } from '../graphql/mutations'
import { onUpdatePerson } from '../graphql/subscriptions';
import Plus from '../assets/plus.svg';
import Minus from '../assets/minus.svg';

function Add() {

  const [people, setPeople] = useState([]);

  useEffect(() => {

    const subscriptionFilter = { filter: { } }

    const fetchPeople = async () => {
      const result = await API.graphql(graphqlOperation(listPeople))
      let temp = result.data.listPeople.items;
      temp = temp.sort((p1, p2) => (p1.shots < p2.shots) ? 1 : (p1.shots > p2.shots) ? -1 : 0);
      setPeople(temp)
    }

    fetchPeople();

    const subscription = API.graphql(graphqlOperation(onUpdatePerson)).subscribe({
      next: ({ value }) => {
        setPeople(people => {
          let temp = [];

          const toUpdateIndex = people.findIndex(item => item.id === value.data.onUpdatePerson.id)
          if (toUpdateIndex === - 1) {
            temp = [...people, value.data.onUpdatePerson]
            return temp.sort((p1, p2) => (p1.shots < p2.shots) ? 1 : (p1.shots > p2.shots) ? -1 : 0);
          }
          temp = [...people.slice(0, toUpdateIndex), value.data.onUpdatePerson, ...people.slice(toUpdateIndex + 1)]
          return temp.sort((p1, p2) => (p1.shots < p2.shots) ? 1 : (p1.shots > p2.shots) ? -1 : 0);
        })
      }
    })

  }, []);

  return (
    <div className='grid'>
      <div className='mx-6 grid grid-cols-2 items-center my-8'>
        <img className=' w-56' alt='Shot Counter' src={Logo} />
        <p className='text-[#89C4E1] font-shot justify-self-end text-xl lg:text-4xl'><Clock /></p>
      </div>

      <div className='flex flex-col gap-4'>
        {people.map((person) => (
          <div className="p-6 lg:p-10 shadow-lg mx-6 my-2 lg:m-4 rounded-xl bg-[#58287F] flex gap-4 justify-between items-center">
            <div className='h-full'>
              <button className='h-full' onClick={async () => {
                  API.graphql(graphqlOperation(updatePerson, {
                    input: {
                      id: person.id,
                      shots: person.shots - 1
                    }
                  }))
              }}><img className=' w-8' alt='-' src={Minus}/></button>
            </div>
            <div className="">
              <p className="text-[#89C4E1] font-num text-5xl lg:text-9xl text-center">{person.shots}</p>
              <h2 className="font-shot text-4xl lg:text-6xl">{person.name}</h2>
            </div>
            <div className='h-full'>
              <button className='h-full' onClick={async () => {
                  API.graphql(graphqlOperation(updatePerson, {
                    input: {
                      id: person.id,
                      shots: person.shots + 1
                    }
                  }))
              }}><img className=' w-8' alt="+" src={Plus} /></button>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
}

export default Add;