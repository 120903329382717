
function ShotsCard({ name, shots }) {
  return (
    <div className="p-6 lg:p-10 shadow-lg mx-6 my-2 lg:m-4 rounded-xl bg-[#58287F] flex lg:flex-col gap-4 lg:justify-center items-center">
      <div className="">
        <p className="text-[#89C4E1] font-num text-5xl lg:text-9xl lg:text-center">{shots}</p>
      </div>
      <h2 className="font-shot text-4xl lg:text-6xl">{name}</h2>
    </div>
  );
}

export default ShotsCard;