import ShotsCard from './ShotsCard';
import Logo from '../shots_logo.svg';
import Clock from '../components/Clock';
import { useEffect, useState } from 'react';
import { listPeople } from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify'
import { onUpdatePerson } from '../graphql/subscriptions';
import QR from '../assets/qr_code.svg';
function Dashboard() {

  const [people, setPeople] = useState([]);

  useEffect(() => {

    const subscriptionFilter = { filter: { } }

    const fetchPeople = async () => {
      const result = await API.graphql(graphqlOperation(listPeople))
      let temp = result.data.listPeople.items;
      temp = temp.sort((p1, p2) => (p1.shots < p2.shots) ? 1 : (p1.shots > p2.shots) ? -1 : 0);
      setPeople(temp)
    }

    fetchPeople();

    const subscription = API.graphql(graphqlOperation(onUpdatePerson)).subscribe({
      next: ({ value }) => {
        setPeople(people => {
          let temp = [];

          const toUpdateIndex = people.findIndex(item => item.id === value.data.onUpdatePerson.id)
          if (toUpdateIndex === - 1) {
            temp = [...people, value.data.onUpdatePerson]
            return temp.sort((p1, p2) => (p1.shots < p2.shots) ? 1 : (p1.shots > p2.shots) ? -1 : 0);
          }
          temp = [...people.slice(0, toUpdateIndex), value.data.onUpdatePerson, ...people.slice(toUpdateIndex + 1)]
          return temp.sort((p1, p2) => (p1.shots < p2.shots) ? 1 : (p1.shots > p2.shots) ? -1 : 0);
        })
      }
    })

  }, []);

  return (
    <div className=' h-screen grid items-center'>
      <div className='mx-6 grid grid-cols-2 lg:grid-cols-3 items-center my-8'>
        <img className=' w-56' alt='Shot Counter' src={Logo} />
        <div className='hidden lg:flex justify-center'>
          <img className='w-20' src={QR}/>
        </div>
        <p className='text-[#89C4E1] font-shot justify-self-end text-xl lg:text-4xl'><Clock /></p>
      </div>
      <div>
        <div className='grid grid-cols-1 lg:grid-cols-5'>
          <div className=' col-span-3'>
            <ShotsCard name={people[0]?.name} shots={people[0]?.shots} />
          </div>
          <div className='col-span-2'>
            <ShotsCard name={people[1]?.name} shots={people[1]?.shots} />
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-4'>
          {people.slice(2, 6).map((person, index) => (
            <ShotsCard key={index} name={person.name} shots={person.shots} />
          ))}
        </div>
      </div> 
    </div>
  );
}

export default Dashboard;